"use client";

import { faTwitter, faVk, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faMicroscope } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";

import { useSession } from "../../components/session";
import { HeaderMenu } from "../header-content";
import Footer from "../footer";

function Hero({ ...props }) {
  const { user } = useSession();

  return (
    <div className="text-center sm:pt-12">
      <div className="px-5 pt-5">
        <h1 className="fw-bold text-4xl">SEM Analyzer</h1>
        <div className="mx-auto px-4 md:w-2/3 xl:w-1/2">
          <p className="mb-4 mt-3 text-xl font-light">
            Introducing our cutting-edge online service for processing and analyzing Scanning Electron Microscope (SEM) images, designed to meet the specific needs of petrology, geology, and mineralogy. Our comprehensive solution supports a wide range of microscope models from various manufacturers, ensuring compatibility and flexibility. With an extensive set of preprocessing, processing, and analysis features, our tool seamlessly transitions you from manual image handling to fully automated workflows, significantly enhancing efficiency and accuracy in your research and industrial applications.
          </p>
          <div className="flex gap-2 max-sm:flex-col sm:flex-row sm:justify-center">
            <Link href="/projects" className="daisy-btn daisy-btn-primary">
              Try Alpha Version
            </Link>
            <Link href="/docs" className="daisy-btn daisy-btn-secondary">
              Documentation
            </Link>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-h-[30vh] overflow-hidden">
        <Image
          src="/assets/screenshot.png"
          className="mx-auto my-4 h-auto max-w-full rounded border shadow-lg"
          alt="SEM Analyzer (screenshot)"
          loading="lazy"
          width={700}
          height={500}
        />
      </div>
    </div>
  );
}

function Features({ ...props }) {
  const features = [
    {
      id: 1,
      icon: faMicroscope,
      title: "Featured title",
      description:
        "Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.",
      button: "Primary button",
      href: "#",
    },
    {
      id: 2,
      icon: faMicroscope,
      title: "Featured title",
      description:
        "Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.",
      button: "Primary button",
      href: "#",
    },
    {
      id: 3,
      icon: faMicroscope,
      title: "Featured title",
      description:
        "Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.",
      button: "Primary button",
      href: "#",
    },
  ];

  return (
    <div className="bg-neutral text-neutral-content" {...props}>
      <div className="container mx-auto flex flex-col gap-4 px-3 py-4 sm:flex-row sm:gap-6 sm:px-4 sm:py-5 lg:gap-8">
        {features.map((feature) => (
          <div key={feature.id} className="flex flex-col items-center lg:flex-row lg:items-start">
            <FontAwesomeIcon icon={feature.icon} size="2x" className="lg:me-4" />
            <div>
              <h3 className="text-2xl font-bold">{feature.title}</h3>
              <p className="py-2">{feature.description}</p>
              <a href={feature.href} className="daisy-btn daisy-btn-primary daisy-btn-sm">
                {feature.button}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Pricing({ ...props }) {
  return (
    <div className="container mx-auto flex flex-col gap-8 px-3 py-4 sm:flex-row">
      <div className="daisy-card flex-1 overflow-hidden border bg-base-100 shadow-xl">
        <h5 className="border-b bg-base-200 p-3 text-center">Free!</h5>
        <div className="daisy-card-body p-3">
          <div className="text-center">
            <span>
              $0<small className="opacity-80">/mo</small>
            </span>
            <ul className="my-3">
              <li>10 users included</li>
              <li>2 GB of storage</li>
              <li>Email support</li>
              <li>Help center access</li>
            </ul>
          </div>
          <button className="daisy-btn daisy-btn-outline daisy-btn-primary daisy-btn-block">Sign up for free</button>
        </div>
      </div>

      <div className="daisy-card flex-1 overflow-hidden border bg-base-100 shadow-xl">
        <h5 className="border-b bg-base-200 p-3 text-center">Pro</h5>
        <div className="daisy-card-body p-3">
          <div className="text-center">
            <span>
              $5<small className="opacity-80">/mo</small>
            </span>
            <ul className="my-3">
              <li>20 users included</li>
              <li>10 GB of storage</li>
              <li>Priority email support</li>
              <li>Help center access</li>
            </ul>
          </div>
          <button className="daisy-btn daisy-btn-outline daisy-btn-primary daisy-btn-block">Get Started</button>
        </div>
      </div>

      <div className="daisy-card flex-1 overflow-hidden border border-primary bg-base-100 shadow-xl">
        <h5 className="border-b bg-primary p-3 text-center text-primary-content">Enterprise</h5>
        <div className="daisy-card-body p-3">
          <div className="text-center">
            <span>
              $10<small className="opacity-80">/mo</small>
            </span>
            <ul className="my-3">
              <li>30 users included</li>
              <li>15 GB of storage</li>
              <li>Phone and email support</li>
              <li>Help center access</li>
            </ul>
          </div>
          <button className="daisy-btn daisy-btn-outline daisy-btn-primary daisy-btn-block">Contact us</button>
        </div>
      </div>
    </div>
  );
}

export default function Page() {
  return (
    <>
      <HeaderMenu>
        <ul className="daisy-menu lg:daisy-menu-horizontal max-lg:rounded-box max-lg:bg-base-100 max-lg:p-2 max-lg:shadow">
          <li>
            <Link href="#home">Home</Link>
          </li>
          {/* <li>
            <Link href="#features">Features</Link>
          </li>
          <li>
            <Link href="#pricing">Pricing</Link>
          </li> */}
        </ul>
      </HeaderMenu>

      <Hero id="home" />
    </>
  );
}
